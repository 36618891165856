<template>
  <div class="privacy-wrapper">
    <h1 class="text--dark text-bold">Integritetspolicy</h1>

    <h2 class="text--dark" style="margin: 40px 0 0 0">Appen Blomstra</h2>

    <h4 class="text--dark">Introduktion</h4>
    <p class="text--dark">Välkommen till vår app. Vi värnar om din integritet och vill att du ska känna dig trygg när du använder vår app. Därför har vi skapat denna integritetspolicy för att förklara hur vi hanterar din information.</p>

    <h4 class="text--dark">Insamling av data</h4>
    <p class="text--dark">Vi samlar inte in någon personlig information eller data från användare av vår app. Vi lagrar ingen information som kan identifiera dig, såsom ditt namn, e-postadress, telefonnummer eller platsdata.</p>

    <h4 class="text--dark">Användning av data</h4>
    <p class="text--dark">Eftersom vi inte samlar in någon data, använder vi inte heller någon data. Din aktivitet i appen övervakas inte och ingen information lagras eller delas med tredje part.</p>

    <h4 class="text--dark">Säkerhet</h4>
    <p class="text--dark">Trots att vi inte samlar in någon data, är vi engagerade i att säkerställa att vår app är säker att använda. Vi använder moderna säkerhetsmetoder och tekniker för att skydda vår app från obehörig åtkomst och säkerhetshot.</p>

    <h4 class="text--dark">Ändringar i integritetspolicyn</h4>
    <p class="text--dark">Vi kan komma att uppdatera denna integritetspolicy vid behov. Vi rekommenderar att du regelbundet granskar denna sida för att hålla dig informerad om hur vi skyddar din integritet. Eventuella ändringar i denna policy träder i kraft omedelbart efter att de har publicerats på denna sida.</p>

    <h4 class="text--dark">Kontakta oss</h4>
    <p class="text--dark">Om du har några frågor eller funderingar angående denna integritetspolicy, vänligen kontakta oss på <a class="hoverable text--primary" href="mailto:blomstrandeliv@gmail.com">blomstrandeliv@gmail.com</a></p>

    <p class="text--dark">Tack för att du använder vår app. Vi uppskattar ditt förtroende och din integritet är vår högsta prioritet.</p>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Integritetspolicy',
  },
}
</script>

<style scoped>

.privacy-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  margin-bottom: 80px;
  margin-left: var(--horizontal-margin);
  margin-right: var(--horizontal-margin);
}

</style>